<!--
  -- Created by zed on 2021/11/15
  -- Copyright © 2017 www.moemone.com. All rights reserved.
  --
  -- 扫码下载页面
-->
<template>
  <div class="download">
    <div class="download-bg" :style="{'backgroundImage': 'url(' + `${VUE_APP_OSS_URL}/webSite/download-bg.png` + ')'}" />
    <div v-if="showTour" class="download-tour">
      <img :src="`${VUE_APP_OSS_URL}/webSite/download-tour.png`" alt="tour">
    </div>
    <div class="download-header">
      <div class="download-header_logo">
        <img :src="`${VUE_APP_OSS_URL}/webSite/download-logo.png`" alt="logo">
      </div>
      <div class="download-header_btn">
        <img :src="`${VUE_APP_OSS_URL}/webSite/download-btn.png`" alt="btn" @click="jumpToDownloadPage">
      </div>
    </div>
    <img class="download-phone" :src="`${VUE_APP_OSS_URL}/webSite/download-phone.png`" alt="phone">
  </div>
</template>

<script>
import { DOWNLOAD_LINK } from "@/settings"

export default {
  name: "Download",
  data() {
    return {
      platform: null,
      userAgent: null,
      showTour: false,
      VUE_APP_OSS_URL: process.env.VUE_APP_OSS_URL
    }
  },
  methods: {
    jumpToDownloadPage() {
      this.platform = navigator.platform
      this.userAgent = navigator.userAgent
      let userAgent = navigator.userAgent
      if ((/MicroMessenger/i).test(userAgent)) {
        console.log("是否是在微信中打开")
        this.showTour = true
      } else {
        let isIOS = userAgent.indexOf("iPhone") > -1 || userAgent.indexOf("Mac OS") > -1
        if (isIOS) {
          console.log("苹果系统")
          window.location.href = DOWNLOAD_LINK.IOS
        }
        let isAndroid = userAgent.indexOf("Android") > -1 || userAgent.indexOf("Linux") > -1
        if (isAndroid) {
          console.log("安卓系统")
          window.location.href = DOWNLOAD_LINK.ANDROID
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.download {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  line-height: 1;
  box-sizing: border-box;
  &-tour {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.7);
    z-index: 3;
    img {
      position: absolute;
      right: 20px;
      top: 20px;
      width: 278px;
    }
  }
  &-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: 100% auto;
    background-position: top;
    background-repeat: no-repeat;
    z-index: 1;
  }
  &-header {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 250px;
    z-index: 2;
    &_logo {
      display: flex;
      justify-content: center;
      margin: 60px 0 40px;
      img {
        width: 60vw;
      }
    }
    &_btn {
      display: flex;
      justify-content: center;
      img {
        width: 46vw;
      }
    }
  }
  &-phone {
    position: relative;
    width: 100%;
    z-index: 2;
  }
}
</style>
